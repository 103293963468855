import * as React  from 'react'
import Layout from '../components/layout'

const AboutPage = () => {
  return (
    <Layout pageTitle="About Page">
      <p>The about page.</p>
    </Layout>
  )
}

export default AboutPage